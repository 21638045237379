import type { NextPage } from "next";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import Head from "next/head";
import {
  Box,
  Checkbox,
  Container,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { green, grey } from "@mui/material/colors";
import { initializeApp } from "firebase/app";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ErrorIcon from "@mui/icons-material/Error";

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=fr.phenix.codeequilibre.prod";
const APP_STORE_URL =
  "https://apps.apple.com/fr/app/code-équilibre/id1566521353";

const firebaseConfig = {
  apiKey: "AIzaSyCqqeRmBNvPx8hXln6gRfZadclKsOa-PIw",
  authDomain: "coe-poc-7ee79.firebaseapp.com",
  projectId: "coe-poc-7ee79",
  storageBucket: "coe-poc-7ee79.appspot.com",
  messagingSenderId: "16191355368",
  appId: "1:16191355368:web:cfc541ca91fb545cb28c5d",
  measurementId: "G-NMY2P1KF3F",
};

initializeApp(firebaseConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: "#2dd09c",
    },
    secondary: {
      main: green[500],
    },
  },
});

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
const TOS_URL = "https://www.code-equilibre.fr/info/cguv";
const PRIVACY_URL = "https://www.code-equilibre.fr/info/cpdp";

type Inputs = {
  email: string;
  password: string;
  partnerCode: string;
  acceptTos: boolean;
};

const Home: NextPage = () => {
  const router = useRouter();

  const initialPartnerCode = router.query["partnerCode"];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Inputs>();

  useEffect(() => {
    if (
      initialPartnerCode !== undefined &&
      !Array.isArray(initialPartnerCode)
    ) {
      setValue("partnerCode", initialPartnerCode);
    }
  }, [initialPartnerCode, setValue]);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [signUpError, setSignUpError] = useState<string | undefined>(undefined);

  const [isSuccess, setIsSuccess] = useState(false);

  const signUp: SubmitHandler<Inputs> = async ({
    email,
    password,
    partnerCode,
  }: Inputs) => {
    setSignUpError(undefined);
    setLoading(true);

    let idToken: string;
    console.log("called");
    try {
      const resp = await createUserWithEmailAndPassword(
        getAuth(),
        email,
        password
      );
      idToken = await resp.user.getIdToken();
    } catch (e) {
      if (e instanceof Error && e.message.includes("email-already-in-use")) {
        setSignUpError(
          `Il existe déjà un compte avec l'adresse email ${email}`
        );
      } else {
        setSignUpError(`Impossible de créer le compte`);
      }
      // TODO: Sentry reporting
      return;
    } finally {
      setLoading(false);
    }

    try {
      const response = await fetch(BASE_URL + "/partners/attach-to-partner", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          code: partnerCode,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });
      if (response.status === 401) {
        setSignUpError(
          `Compte créé mais impossible de se rattacher au partenaire avec le code ${partnerCode}`
        );
        return;
      }
      // TODO: Sentry reporting
    } catch (e) {
      setSignUpError(
        `Compte créé mais une erreur inattendue s'est produite dans l'activation du code partenaire.`
      );
      // TODO: Sentry reporting
    } finally {
      setLoading(false);
    }

    setIsSuccess(true);
  };

  const [showCreatedPassword, setShowCreatedPassword] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <meta name="theme-color" content="#2dd09c" />
      </Head>
      <Box
        style={{
          backgroundColor: grey[100],
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
        py={2}
      >
        <Head>
          <title>Code Équilibre</title>
          <meta name="description" content="Accès partenaires" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Container style={{ maxWidth: 500 }}>
          <Paper elevation={6}>
            <Box p={5} style={{ textAlign: "center" }}>
              <Box mb={2}>
                <img src="/logo.png" width={150} />
              </Box>
              <Box pb={3}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Inscription avec un code partenaire
                </Typography>
              </Box>

              {isSuccess && (
                <Box>
                  <AssignmentTurnedInIcon color="success" fontSize="large" />
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "success.main" }}
                  >
                    Votre compte a été créé
                  </Typography>
                  <List>
                    <ListItem>
                      <Typography variant="body1">
                        1. Vous pouvez télécharger l'application Code Équilibre
                        depuis l'
                        <Link href={APP_STORE_URL} target="_blank">
                          App Store
                        </Link>{" "}
                        ou le{" "}
                        <Link href={PLAY_STORE_URL} target="_blank">
                          Play Store
                        </Link>
                        .
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        variant="body1"
                        sx={{ wordBreak: "break-all" }}
                      >
                        2. Connectez-vous sur l'application avec votre email{" "}
                        <Typography
                          sx={{ fontWeight: "bold" }}
                          component="span"
                        >
                          {watch("email")}
                        </Typography>
                        .<br />(
                        {showCreatedPassword ? (
                          "Mot de passe : " + watch("password")
                        ) : (
                          <Link
                            onClick={() => setShowCreatedPassword(true)}
                            sx={{ fontWeight: "bold", cursor: "pointer" }}
                          >
                            Afficher le mot de passe
                          </Link>
                        )}
                        )
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1">
                        3. C'est bon, vous bénéficiez des fonctionnalités
                        premium !
                      </Typography>
                    </ListItem>
                  </List>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="h6" mt={2} sx={{ fontWeight: "bold" }}>
                      Vous êtes sur Android
                    </Typography>
                    <Link href={PLAY_STORE_URL} target="_blank">
                      <img
                        src="./android_download_button.png"
                        width={250}
                        height={98}
                      />
                    </Link>
                    <img src="./android_qrcode.svg" width={220} />
                    <Divider />
                    <Typography
                      variant="h6"
                      mt={2}
                      mb={2}
                      sx={{ fontWeight: "bold" }}
                    >
                      Vous êtes sur iOS
                    </Typography>
                    <Link href={APP_STORE_URL} target="_blank">
                      <img src="./ios_download_button.svg" width={220} />
                    </Link>
                    <img src="./ios_qrcode.svg" width={220} />
                  </Box>
                </Box>
              )}

              {!isSuccess && (
                <form onSubmit={handleSubmit(signUp)}>
                  <Stack spacing={2}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      type="email"
                      {...register("email", { required: "L'email est requis" })}
                      error={errors.email !== undefined}
                      required
                    />
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Mot de passe *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        error={errors.password !== undefined}
                        {...register("password", {
                          required: true,
                          minLength: {
                            value: 6,
                            message:
                              "Votre mot de passe doit faire au moins 6 caractères",
                          },
                        })}
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="afficher le mot de passe"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Mot de passe *"
                      />
                      <FormHelperText>
                        {errors.password !== undefined
                          ? errors.password.message
                          : "Minimum 6 caractères"}
                      </FormHelperText>
                    </FormControl>

                    {initialPartnerCode !== undefined ? (
                      <Typography>
                        Votre code partenaire est <b>{watch("partnerCode")}</b>
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-basic"
                        label="Code partenaire"
                        variant="outlined"
                        fullWidth
                        {...register("partnerCode", {
                          required: "Un code partenaire est requis",
                        })}
                        error={errors.partnerCode !== undefined}
                        required
                      />
                    )}

                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            alignSelf: "flex-start",
                            marginTop: "-10px",
                          }}
                          {...register("acceptTos", {
                            required:
                              "Vous devez accepter les Conditions Générales d'Utilisation et de Vente ainsi que la Politique de Confidentialité pour continuer",
                          })}
                        />
                      }
                      label={
                        <Typography sx={{ textAlign: "left" }}>
                          Pour continuer vous devez prendre connaissance et
                          accepter{" "}
                          <Link
                            href={TOS_URL}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ textDecoration: "none" }}
                          >
                            Conditions Générales d'Utilisation et de Vente
                          </Link>{" "}
                          et notre{" "}
                          <Link
                            href={PRIVACY_URL}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ textDecoration: "none" }}
                          >
                            Politique de confidentialité
                          </Link>
                        </Typography>
                      }
                    />
                    {errors.acceptTos !== undefined && (
                      <Typography variant="body2" color="error">
                        {errors.acceptTos.message}
                      </Typography>
                    )}

                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                      type="submit"
                    >
                      {loading ? " " : "S'inscrire"}
                    </LoadingButton>
                  </Stack>
                </form>
              )}

              {/* ERROR HANDLING */}

              {signUpError !== undefined && (
                <Box mt={3}>
                  <Divider />
                  <Box mt={3}>
                    <ErrorIcon color="error" fontSize="large" />
                    <Typography mt={1} variant="body1">
                      Une erreur est survenue, vous pouvez réessayer ou bien{" "}
                      <Link href="mailto:contact@code-equilibre.fr">
                        contacter le support
                      </Link>
                      .
                    </Typography>
                    <Typography
                      variant="body2"
                      p={0.5}
                      mt={1}
                      sx={{ color: grey[400], backgroundColor: grey[50] }}
                    >
                      {signUpError}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "center" }}
            mt={2}
          >
            © 2021 SAS Phénix
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
